import UTIF from 'utif';

export const createImagePreviewUrl = async (file: File): Promise<string> => {
  if (file.type.includes('tiff') || file.type.includes('tif')) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (e.target?.result) {
          const buffer = e.target.result as ArrayBuffer;
          const ifds = UTIF.decode(buffer);
          UTIF.decodeImage(buffer, ifds[0]);
          const rgba = UTIF.toRGBA8(ifds[0]);

          const canvas = document.createElement('canvas');
          canvas.width = ifds[0].width;
          canvas.height = ifds[0].height;

          const ctx = canvas.getContext('2d');
          if (!ctx) throw new Error('Could not get canvas context');

          const imageData = ctx.createImageData(canvas.width, canvas.height);
          imageData.data.set(rgba);
          ctx.putImageData(imageData, 0, 0);

          resolve(canvas.toDataURL('image/png'));
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }
  return URL.createObjectURL(file);
};
