import React, { useState } from 'react';

interface SmartImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  baseUrl: string;
  imageName: string;
}

const SmartImage: React.FC<SmartImageProps> = ({
  baseUrl,
  imageName,
  ...props
}) => {
  const [src, setSrc] = useState(`${baseUrl}${imageName}.png`);
  const [triedJpg, setTriedJpg] = useState(false);

  const handleError = () => {
    if (!triedJpg) {
      setSrc(`${baseUrl}${imageName}.jpg`);
      setTriedJpg(true);
    } else {
      // Om både .png och .jpg misslyckas kan du sätta en reservbild eller hantera det på annat sätt
      // Exempelvis: setSrc('path_to_placeholder_image');
    }
  };

  return <img src={src} onError={handleError} {...props} />;
};

export default SmartImage;
