import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  IconButton,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

interface Import {
  importId: number;
  importName: string;
  status: 'draft' | 'published';
  createdAt: string;
  updatedAt?: string;
}

const ProductImportList: React.FC = () => {
  const [imports, setImports] = useState<Import[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const fetchImports = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${config.API_URL}/product-imports?page=${page + 1}&limit=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log('API response data:', response.data);
      setImports(response.data.imports);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Fel vid hämtning av importer:', error);
    }
  };

  useEffect(() => {
    fetchImports();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (importId: number) => {
    console.log('Editing import with ID:', importId);
    navigate(`/product-import/edit/${importId}`);
  };

  const handleView = (importId: number) => {
    navigate(`/product-import/view/${importId}`);
  };

  const handleCreateNew = () => {
    navigate('/product-import/new');
  };

  return (
    <Container maxWidth={false}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4">Produktimporter</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateNew}
        >
          Skapa ny import
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Skapad</TableCell>
              <TableCell>Senast ändrad</TableCell>
              <TableCell>Åtgärder</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {imports.map((imp) => {
              console.log('Import object:', imp);
              return (
                <TableRow key={`import-row-${imp.importId}`}>
                  <TableCell>{imp.importName}</TableCell>
                  <TableCell>
                    <Chip
                      label={
                        imp.status === 'published' ? 'Publicerad' : 'Utkast'
                      }
                      color={imp.status === 'published' ? 'success' : 'warning'}
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(imp.createdAt).toLocaleDateString('sv-SE')}
                  </TableCell>
                  <TableCell>
                    {imp.updatedAt
                      ? new Date(imp.updatedAt).toLocaleDateString('sv-SE')
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {imp.status === 'draft' ? (
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(imp.importId)}
                      >
                        <EditIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => handleView(imp.importId)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={totalPages * rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rader per sida:"
      />
    </Container>
  );
};

export default ProductImportList;
