// selectColumn.ts
import { Column, CellComponent } from 'react-datasheet-grid';
import SelectComponent from './selectComponent';

type AttributeType = 'attribute1' | 'attribute2' | 'attribute3';

interface Choice {
  label: string;
  value: string;
}

interface SelectOptions {
  choices: Choice[];
  disabled?: boolean;
  columnId: AttributeType;
}

interface BrandSelectOptions {
  choices: Choice[];
  disabled?: boolean;
}

interface PasteOptions {
  rowData: string | null;
  value: string;
  rowIndex: number;
}

export const selectColumn = (
  options: SelectOptions,
): Column<string | null, SelectOptions> => ({
  component: SelectComponent,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  disabled: options.disabled,
  deleteValue: () => null,
  copyValue: ({ rowData }) =>
    options.choices.find((choice) => choice.value === rowData)?.value ?? null,
  pasteValue: ({ value, rowData, rowIndex }: PasteOptions) => {
    if (!value) {
      return null;
    }

    const cleanValue = value.trim();

    const exactMatch = options.choices.find(
      (choice) =>
        choice.value.toLowerCase() === cleanValue.toLowerCase() ||
        choice.label.toLowerCase() === cleanValue.toLowerCase(),
    );

    if (exactMatch) {
      return exactMatch.value;
    }

    if (typeof window !== 'undefined' && window.dispatchEvent) {
      const event = new CustomEvent('unmatched-attribute', {
        detail: {
          value: cleanValue,
          rowIndex,
          attributeType: options.columnId,
          columnId: options.columnId,
          suggestions: options.choices.map((c) => c.value),
        },
      });
      window.dispatchEvent(event);
    }

    return null;
  },
});

export const brandSelectColumn = (
  options: BrandSelectOptions,
): Column<string | null, BrandSelectOptions> => ({
  component: SelectComponent,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  disabled: options.disabled,
  deleteValue: () => null,
  copyValue: ({ rowData }) =>
    options.choices.find((choice) => choice.value === rowData)?.value ?? null,
  pasteValue: ({ value }) => {
    if (!value) {
      return null;
    }

    const cleanValue = value.trim();
    const exactMatch = options.choices.find(
      (choice) =>
        choice.value.toLowerCase() === cleanValue.toLowerCase() ||
        choice.label.toLowerCase() === cleanValue.toLowerCase(),
    );

    return exactMatch?.value ?? null;
  },
});
