// UploadMediaModal.tsx
import React, { useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import DropzoneArea from './DropzoneArea';

interface UploadMediaModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: UploadData) => void;
  uploadedImages: string[];
  handlePreview: (data: UploadData) => void;
  productNumber: string;
  availableColors: string[];
  initialColorCode: string;
  attribute1Number: string; // Ny prop
}

interface UploadData {
  articleNumber: string;
  colorCode: string;
  attribute1Number: string;
  images: File[];
  transparentIndices: number[];
}

const UploadMediaModal: React.FC<UploadMediaModalProps> = ({
  open,
  onClose,
  onSubmit,
  uploadedImages,
  handlePreview,
  productNumber,
  availableColors,
  initialColorCode,
  attribute1Number, // Ny prop
}) => {
  const [selectedColor, setSelectedColor] = useState<string>(
    initialColorCode || '',
  );
  const [files, setFiles] = useState<File[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [transparentIndices, setTransparentIndices] = useState<number[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    const imageFiles = acceptedFiles.filter((file) =>
      file.type.startsWith('image/'),
    );

    if (imageFiles.length < acceptedFiles.length) {
      alert('Endast bildfiler är tillåtna (PNG, JPG, JPEG, GIF, WEBP)');
    }

    setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
  };

  const handlePreviewClick = async () => {
    if (!selectedColor || files.length === 0) {
      alert('Vänligen välj en färg och lägg till bilder.');
      return;
    }

    setIsProcessing(true);

    const formData = new FormData();
    files.forEach((file) => formData.append('images', file));
    formData.append('articleNumber', productNumber);
    formData.append('colorCode', selectedColor);
    formData.append('attribute1Number', attribute1Number);
    formData.append('transparentIndices', JSON.stringify(transparentIndices));

    await handlePreview({
      articleNumber: productNumber,
      colorCode: selectedColor,
      attribute1Number: attribute1Number,
      images: files,
      transparentIndices: transparentIndices,
    });

    setIsProcessing(false);
    setFiles([]);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      await onSubmit({
        articleNumber: productNumber,
        colorCode: selectedColor,
        attribute1Number: attribute1Number,
        images: files,
        transparentIndices: transparentIndices,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
  };

  const handleCloseImageViewer = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div
          className="modal-content"
          style={{
            padding: '20px',
            backgroundColor: '#1E1E1E',
            margin: '50px auto',
            maxWidth: '600px',
            maxHeight: '80vh',
            overflowY: 'auto',
            position: 'relative',
            borderRadius: '16px',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
            color: '#FFFFFF',
          }}
        >
          {isSaving && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(30, 30, 30, 0.7)',
                backdropFilter: 'blur(4px)',
                zIndex: 1,
                borderRadius: '16px',
              }}
            >
              <CircularProgress sx={{ color: '#BB86FC' }} />
            </div>
          )}

          <Typography
            variant="h6"
            gutterBottom
            sx={{
              background: 'linear-gradient(45deg, #BB86FC, #03DAC6)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Ladda upp bilder
          </Typography>

          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
          >
            Artikelnummer: {productNumber}
          </Typography>

          <Autocomplete
            options={availableColors}
            value={selectedColor}
            onChange={(event, newValue) => {
              setSelectedColor(newValue || '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Färg"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    background: 'rgba(255, 255, 255, 0.05)',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#BB86FC',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                  '& .MuiOutlinedInput-input': {
                    color: '#FFFFFF',
                  },
                }}
              />
            )}
            sx={{ mb: 2 }}
          />

          {/* Passera filer till DropzoneArea för förhandsgranskning och omordning */}
          <DropzoneArea
            onDrop={onDrop}
            files={files}
            setFiles={setFiles}
            transparentIndices={transparentIndices}
            setTransparentIndices={setTransparentIndices}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <Button
              onClick={handlePreviewClick}
              variant="outlined"
              disabled={isProcessing}
            >
              {isProcessing ? 'Bearbetar...' : 'Förhandsgranska'}
            </Button>
          </div>

          {/* Visa de uppladdade bilderna */}
          {uploadedImages.length > 0 && (
            <div>
              <Typography variant="h6">Bearbetade bilder:</Typography>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  maxWidth: '100%',
                  overflow: 'hidden',
                }}
              >
                {uploadedImages.map((url, index) => {
                  const fileName = url.split('/').pop() || url;
                  const fullUrl = `https://mirakel-api.enghssport.com/processed/${fileName}`;
                  return (
                    <img
                      key={index}
                      src={fullUrl}
                      alt={`Bild ${index + 1}`}
                      style={{
                        width: '100px',
                        height: '140px',
                        margin: '5px',
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleImageClick(fullUrl)}
                    />
                  );
                })}
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
              gap: '10px',
            }}
          >
            <Button
              onClick={onClose}
              sx={{
                color: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                },
              }}
            >
              Avbryt
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={uploadedImages.length === 0}
              sx={{
                background: 'linear-gradient(45deg, #BB86FC, #03DAC6)',
                '&:hover': {
                  background: 'linear-gradient(45deg, #9B66FB, #018786)',
                },
              }}
            >
              Spara
            </Button>
          </div>
        </div>
      </Modal>

      {/* Lägg till ny Modal för förstorad bild */}
      <Modal
        open={!!selectedImage}
        onClose={handleCloseImageViewer}
        aria-labelledby="image-preview-modal"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#1E1E1E',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={selectedImage || ''}
            alt="Förstorad bild"
            style={{
              maxWidth: '100%',
              maxHeight: 'calc(90vh - 100px)',
              objectFit: 'contain',
            }}
          />
          <Button
            onClick={handleCloseImageViewer}
            sx={{
              marginTop: '16px',
              color: 'white',
              borderColor: 'white',
            }}
            variant="outlined"
          >
            Stäng
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UploadMediaModal;
