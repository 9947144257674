import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#BB86FC', // Lila accent
      light: '#E0B0FF',
      dark: '#9B66FB',
    },
    secondary: {
      main: '#03DAC6', // Turkos accent
      light: '#64FFDA',
      dark: '#018786',
    },
    error: {
      main: '#CF6679', // Rosa-röd för fel
    },
    background: {
      default: '#121212', // Mörk bakgrund
      paper: '#1E1E1E', // Mörkgrå för kort/paneler
    },
    success: {
      main: '#00E676', // Neongrön för positiva indikatorer
    },
    warning: {
      main: '#FFB74D', // Orange för varningar
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Inter", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
      background: 'linear-gradient(45deg, #BB86FC, #03DAC6)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '8px 16px',
        },
        contained: {
          background: 'linear-gradient(45deg, #BB86FC, #03DAC6)',
          '&:hover': {
            background: 'linear-gradient(45deg, #9B66FB, #018786)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(30, 30, 30, 0.8)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'rgba(18, 18, 18, 0.8)',
          backdropFilter: 'blur(10px)',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'none',
          backgroundImage: 'none',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        },
      },
    },
  },
});

export default theme;
