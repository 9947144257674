import React, { useState, useContext, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import config from '../config';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { setToken, setPermissions } = useContext(AuthContext);

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${config.API_URL}/auth/login`, {
        email,
        password,
      });
      const token = response.data.token;
      const userPermissions = response.data.permissions;

      localStorage.setItem('token', token);
      localStorage.setItem('permissions', JSON.stringify(userPermissions));
      setToken(token);
      setPermissions(userPermissions);
      navigate('/dashboard');
    } catch (err: any) {
      setError(err.response?.data?.message || 'Login failed');
    }
  };

  // WebGL animation and canvas logic
  useEffect(() => {
    const canvas = document.querySelector('canvas')!;
    const gl = canvas.getContext('webgl');

    if (!gl) {
      console.error(
        'Unable to initialize WebGL. Your browser may not support it.',
      );
      return;
    }

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    gl.viewport(0, 0, canvas.width, canvas.height);

    const config = {
      particleCount: 7000,
      text: 'MiRAKEL',
      particleSize: 1.5,
      forceMultiplier: 0.001,
      returnSpeed: 0.005,
      velocityDamping: 0.85,
      colorMultiplier: 40000,
      saturationMultiplier: 1000,
      rotationForceMultiplier: 0.5,
      colors: ['#BB86FC', '#03DAC6', '#CF6679'],
    };

    let textCoordinates = [];
    let time = 0;

    const particles = Array.from({ length: config.particleCount }, () => ({
      x: 0,
      y: 0,
      baseX: 0,
      baseY: 0,
      vx: 0,
      vy: 0,
    }));

    const vertexShaderSource = `
            attribute vec2 a_position;
            attribute float a_hue;
            attribute float a_saturation;
            varying float v_hue;
            varying float v_saturation;
            void main() {
                gl_PointSize = ${config.particleSize.toFixed(1)};
                gl_Position = vec4(a_position, 0.0, 1.0);
                v_hue = a_hue;
                v_saturation = a_saturation;
            }
        `;

    const fragmentShaderSource = `
            precision mediump float;
            varying float v_hue;
            varying float v_saturation;
            void main() {
                float c = v_hue * 6.0;
                float x = 1.0 - abs(mod(c, 2.0) - 1.0);
                vec3 color;
                if (c < 1.0) color = vec3(1.0, x, 0.0);
                else if (c < 2.0) color = vec3(x, 1.0, 0.0);
                else if (c < 3.0) color = vec3(0.0, 1.0, x);
                else if (c < 4.0) color = vec3(0.0, x, 1.0);
                else if (c < 5.0) color = vec3(x, 0.0, 1.0);
                else color = vec3(1.0, 0.0, x);
                vec3 finalColor = mix(vec3(1.0), color, v_saturation);
                gl_FragColor = vec4(finalColor, 1.0);
            }
        `;

    function createShader(
      gl: WebGLRenderingContext,
      type: number,
      source: string,
    ) {
      const shader = gl.createShader(type);
      if (!shader) {
        console.error('Error creating shader.');
        return null;
      }
      gl.shaderSource(shader, source);
      gl.compileShader(shader);
      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error(gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
      }
      return shader;
    }

    function createProgram(
      gl: WebGLRenderingContext,
      vertexShader: WebGLShader,
      fragmentShader: WebGLShader,
    ) {
      const program = gl.createProgram();
      if (!program) {
        console.error('Error creating program.');
        return null;
      }
      gl.attachShader(program, vertexShader);
      gl.attachShader(program, fragmentShader);
      gl.linkProgram(program);
      if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.error(gl.getProgramInfoLog(program));
        gl.deleteProgram(program);
        return null;
      }
      return program;
    }

    const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
    if (!vertexShader) {
      console.error('Failed to create vertex shader');
      return;
    }

    const fragmentShader = createShader(
      gl,
      gl.FRAGMENT_SHADER,
      fragmentShaderSource,
    );
    if (!fragmentShader) {
      console.error('Failed to create fragment shader');
      return;
    }

    const program = createProgram(gl, vertexShader, fragmentShader);
    if (!program) {
      console.error('Failed to create program');
      return;
    }

    const positionAttributeLocation = gl.getAttribLocation(
      program,
      'a_position',
    );
    const hueAttributeLocation = gl.getAttribLocation(program, 'a_hue');
    const saturationAttributeLocation = gl.getAttribLocation(
      program,
      'a_saturation',
    );

    const positionBuffer = gl.createBuffer();
    const hueBuffer = gl.createBuffer();
    const saturationBuffer = gl.createBuffer();

    const positions = new Float32Array(config.particleCount * 2);
    const hues = new Float32Array(config.particleCount);
    const saturations = new Float32Array(config.particleCount);

    function getTextCoordinates(text: string) {
      const ctx = document.createElement('canvas').getContext('2d');
      ctx!.canvas.width = canvas.width;
      ctx!.canvas.height = canvas.height;
      const fontSize = Math.min(canvas.width / 4.5, canvas.height / 4.5);
      ctx!.font = `900 ${fontSize}px Arial`;
      ctx!.fillStyle = 'white';
      ctx!.textAlign = 'center';
      ctx!.textBaseline = 'middle';
      ctx!.fillText(text, canvas.width / 2, canvas.height / 2);
      const imageData = ctx!.getImageData(
        0,
        0,
        canvas.width,
        canvas.height,
      ).data;
      const coordinates = [];
      for (let y = 0; y < canvas.height; y += 4) {
        for (let x = 0; x < canvas.width; x += 4) {
          const index = (y * canvas.width + x) * 4;
          if (imageData[index + 3] > 128) {
            coordinates.push({
              x: (x / canvas.width) * 2 - 1,
              y: (y / canvas.height) * -2 + 1,
            });
          }
        }
      }
      return coordinates;
    }

    function createParticles() {
      textCoordinates = getTextCoordinates(config.text);
      for (let i = 0; i < config.particleCount; i++) {
        const randomIndex = Math.floor(Math.random() * textCoordinates.length);
        const { x, y } = textCoordinates[randomIndex];
        particles[i].x = Math.random() * 2 - 1;
        particles[i].y = Math.random() * 2 - 1;
        particles[i].baseX = x;
        particles[i].baseY = y;
      }
    }

    function updateParticles() {
      time += 0.01;
      const offsetX = Math.sin(time) * 0.01;
      const offsetY = Math.cos(time) * 0.01;

      for (let i = 0; i < config.particleCount; i++) {
        const particle = particles[i];

        // Add some random movement occasionally
        if (Math.random() < 0.01) {
          particle.vx += (Math.random() - 0.5) * 0.02;
          particle.vy += (Math.random() - 0.5) * 0.02;
        }

        // Move towards base position with offset
        particle.vx +=
          (particle.baseX + offsetX - particle.x) * config.returnSpeed;
        particle.vy +=
          (particle.baseY + offsetY - particle.y) * config.returnSpeed;

        particle.x += particle.vx;
        particle.y += particle.vy;
        particle.vx *= config.velocityDamping;
        particle.vy *= config.velocityDamping;

        const speed = Math.sqrt(
          particle.vx * particle.vx + particle.vy * particle.vy,
        );
        const hue = (speed * config.colorMultiplier) % 360;

        hues[i] = hue / 360;
        saturations[i] = Math.min(speed * config.saturationMultiplier, 1);
        positions[i * 2] = particle.x;
        positions[i * 2 + 1] = particle.y;
      }

      if (gl) {
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, positions, gl.DYNAMIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, hueBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, hues, gl.DYNAMIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, saturationBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, saturations, gl.DYNAMIC_DRAW);
      }
    }

    function animate() {
      updateParticles();

      if (gl) {
        gl.clear(gl.COLOR_BUFFER_BIT);
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        gl.vertexAttribPointer(
          positionAttributeLocation,
          2,
          gl.FLOAT,
          false,
          0,
          0,
        );
        gl.enableVertexAttribArray(positionAttributeLocation);
        gl.bindBuffer(gl.ARRAY_BUFFER, hueBuffer);
        gl.vertexAttribPointer(hueAttributeLocation, 1, gl.FLOAT, false, 0, 0);
        gl.enableVertexAttribArray(hueAttributeLocation);
        gl.bindBuffer(gl.ARRAY_BUFFER, saturationBuffer);
        gl.vertexAttribPointer(
          saturationAttributeLocation,
          1,
          gl.FLOAT,
          false,
          0,
          0,
        );
        gl.enableVertexAttribArray(saturationAttributeLocation);
        gl.useProgram(program);
        gl.drawArrays(gl.POINTS, 0, config.particleCount);
      }
      requestAnimationFrame(animate);
    }

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      if (gl) {
        gl.viewport(0, 0, canvas.width, canvas.height);
      }
      createParticles();
    });

    if (gl) {
      gl.clearColor(0, 0, 0, 1);
    }
    createParticles();
    animate();

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  // Lägg till funktionen att logga in med Enter-tangenten
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        height: '100vh',
        background: 'linear-gradient(135deg, #121212 0%, #1E1E1E 100%)',
      }}
    >
      {/* Vänster sida - Canvas för animation */}
      <Box
        sx={{
          flex: 1,
          position: 'relative',
          width: { xs: '100%', md: '50%' },
          height: { xs: '50%', md: '100%' },
          background:
            'linear-gradient(45deg, rgba(187, 134, 252, 0.1), rgba(3, 218, 198, 0.1))',
          overflow: 'hidden',
        }}
      >
        <canvas
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </Box>

      {/* Höger sida - Inloggningsformulär */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: '100%', md: '50%' },
          height: { xs: '50%', md: '100%' },
        }}
      >
        <Container maxWidth="sm">
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: 'rgba(30, 30, 30, 0.8)',
              backdropFilter: 'blur(10px)',
              borderRadius: '16px',
              padding: '32px',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                background: 'linear-gradient(45deg, #BB86FC, #03DAC6)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Inloggning
            </Typography>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              fullWidth
              type="email" // Ange rätt typ
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown} // Lägg till Enter-funktionalitet
              autoComplete="email" // Autocomplete för email
              sx={{
                '& .MuiOutlinedInput-root': {
                  background: 'rgba(255, 255, 255, 0.05)',
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#BB86FC',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
                '& .MuiOutlinedInput-input': {
                  color: '#FFFFFF',
                },
              }}
            />
            <TextField
              label="Lösenord"
              type="password"
              variant="outlined"
              margin="normal"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown} // Lägg till Enter-funktionalitet
              autoComplete="current-password" // Autocomplete för lösenord
              sx={{
                '& .MuiOutlinedInput-root': {
                  background: 'rgba(255, 255, 255, 0.05)',
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#BB86FC',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
                '& .MuiOutlinedInput-input': {
                  color: '#FFFFFF',
                },
              }}
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            <Button
              variant="contained"
              fullWidth
              onClick={handleLogin}
              sx={{
                mt: 3,
                mb: 2,
                height: '48px',
                background: 'linear-gradient(45deg, #BB86FC, #03DAC6)',
                '&:hover': {
                  background: 'linear-gradient(45deg, #9B66FB, #018786)',
                },
              }}
            >
              Logga in
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LoginPage;
