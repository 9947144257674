import React, { ReactNode } from 'react';
import { Container } from '@mui/material';
import CustomAppBar from './CustomAppBar'; // Importera AppBar-komponenten

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <CustomAppBar />
      <Container maxWidth={false} sx={{ mt: 4 }}>
        {children}
      </Container>
    </>
  );
};

export default Layout;
