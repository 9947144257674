import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import config from '../config';

interface User {
  id: number;
  username: string;
  email: string;
  created_at: string;
}

interface Permission {
  id: number;
  name: string;
  description: string;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [open, setOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    password: '',
    permissionIds: [] as number[],
  });
  const { permissions: userPermissions } = useContext(AuthContext);

  useEffect(() => {
    fetchUsers();
    fetchPermissions();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/users`);
      setUsers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/permissions`);
      setPermissions(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewUser({ username: '', email: '', password: '', permissionIds: [] });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handlePermissionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const permissionId = parseInt(e.target.value);
    if (e.target.checked) {
      setNewUser({
        ...newUser,
        permissionIds: [...newUser.permissionIds, permissionId],
      });
    } else {
      setNewUser({
        ...newUser,
        permissionIds: newUser.permissionIds.filter(
          (id) => id !== permissionId,
        ),
      });
    }
  };

  const handleCreate = async () => {
    try {
      await axios.post(`${config.API_URL}/users`, newUser);
      fetchUsers();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Användarhantering
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{ mb: 2 }}
      >
        Skapa Ny Användare
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Användarnamn</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Skapad</TableCell>
            <TableCell>Åtgärder</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {new Date(user.created_at).toLocaleDateString()}
              </TableCell>
              <TableCell>
                {/* Lägg till knappar för redigera och ta bort */}
                <Button variant="outlined" color="secondary" size="small">
                  Ta Bort
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Dialog för att skapa ny användare */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Skapa Ny Användare</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Användarnamn"
            name="username"
            fullWidth
            variant="standard"
            value={newUser.username}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            fullWidth
            variant="standard"
            value={newUser.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Lösenord"
            type="password"
            name="password"
            fullWidth
            variant="standard"
            value={newUser.password}
            onChange={handleChange}
          />
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Tilldela Behörigheter
          </Typography>
          <FormGroup>
            {permissions.map((permission) => (
              <FormControlLabel
                key={permission.id}
                control={
                  <Checkbox
                    value={permission.id}
                    checked={newUser.permissionIds.includes(permission.id)}
                    onChange={handlePermissionChange}
                  />
                }
                label={permission.description}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button onClick={handleCreate}>Skapa</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserManagement;
