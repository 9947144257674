import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Unauthorized: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/dashboard');
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Åtkomst nekad
      </Typography>
      <Typography variant="body1" gutterBottom>
        Du har inte behörighet att se denna sida.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleBack}>
        Tillbaka till Dashboard
      </Button>
    </Container>
  );
};

export default Unauthorized;
