import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

interface AuthContextProps {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  permissions: string[];
  setPermissions: React.Dispatch<React.SetStateAction<string[]>>;
}

export const AuthContext = createContext<AuthContextProps>({
  token: null,
  setToken: () => {},
  permissions: [],
  setPermissions: () => {},
});

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token'),
  );
  const [permissions, setPermissions] = useState<string[]>(
    JSON.parse(localStorage.getItem('permissions') || '[]'),
  );

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [token]);

  useEffect(() => {
    localStorage.setItem('permissions', JSON.stringify(permissions));
  }, [permissions]);

  return (
    <AuthContext.Provider
      value={{ token, setToken, permissions, setPermissions }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
